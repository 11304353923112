<template>
   <div>
    <el-result title="404" subTitle="抱歉，页面不存在">
  <template slot="icon">
    <svg-icon name="error" class="icon" />
  </template>
  <template slot="extra">
    <el-button type="primary" @click="back" >返回</el-button>
  </template>
</el-result>
  </div>
</template>

<script>
export default {
  methods:{
    back(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
  .icon-big{
    width: 100px;
    height: 100px;
  }
</style>